<template>
  <main class="main">
    <div class="content">
      <div class="container content__container content__container--w-1074">

        <!--satefy.begin-->
        <div class="account-verification satefy">
          <form action="#" class="account-verification__form" method="POST">
            <div class="account-verification__head">
              <h1 class="account-verification__title">{{$t('safety')}}</h1>
            </div>
            <div class="account-verification__body">
              <div class="account-verification__item">
                <h2 class="account-verification__subtitle">
                  {{$t('change_password')}}
                </h2>
                <div class="container-fluid pe-0 ps-0">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        <label for="old_pwd" class="account-verification__label text-grey40">{{$t('old_password')}}</label>
                        <input type="password" class="form__control" name="old_pwd" id="old_pwd" v-model="old_pwd"
                               placeholder="******" required="">
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        <label for="new_pwd" class="account-verification__label text-grey40">{{$t('new_password')}}</label>
                        <input type="password" class="form__control" name="new_pwd" id="new_pwd" v-model="new_pwd""
                               placeholder="******" required="">
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        <label for="re_new_pwd" class="account-verification__label text-grey40">{{$t('confirm_new_password')}}</label>
                        <input type="password" class="form__control" name="re_new_pwd" id="re_new_pwd" v-model="re_new_pwd"
                               placeholder="******" required="">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="account-verification__foot" style="padding-left: 0px;">
                  <button type="button" class="btn btn-info account-verification__btn-save" @click="setPassword">{{$t('save')}}</button>
                </div>
              </div>
              <div class="account-verification__item">
                <h2 class="account-verification__subtitle">{{$t('twofactor_auth')}}</h2>
                <div class="container-fluid pe-0 ps-0">
                  <div class="row">
                    <div class="col-md-10">
                      <div class="alert text-grey40">
                        <svg class="alert__icon">
                          <use xlink:href="#i-warning"></use>
                        </svg>
                        <div class="alert__text">
                          <p class="mt-0 mb-0">
                            {{ $t('enable2fatip') }}
                            {{ $t('toggle2fatip') }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="satefy__qr">
                        <div class="satefy__qr-pict" v-if="status2fa == 0 && pinCode">
                          <img v-bind:src="qr">
                          
                        </div>
                        <div class="satefy__qr-content">
                          <div class="row align-items-end" v-if="status2fa == 0">
                            <div class="col-md-6">
                              <div class="mb-3 position-relative">
                                <label for="pinCode" class="form__label d-block text-grey40">
                                  {{$t('code')}}
                                </label>
                                <input type="text" class="form__control"
                                       name="pinCode" id="pinCode"
                                       readonly @click="copyKey"
                                       v-model="pinCode">
                                <svg class="form__control-icon" v-if="pinCode != ''" @click="copyKey">
                                  <use xlink:href="#i-copy"></use>
                                </svg>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <button
                                  type="button"
                                  v-if="pinCode === ''"
                                  @click="generatePinCode"
                                  class="btn btn-gray-outline satefy__btn">
                                {{$t('generate')}}
                              </button>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="mb-3 mt-3 position-relative">
                                <label for="code2Auth" class="form__label d-block text-grey40">
                                  {{$t('twofactor_code')}}
                                </label>
                                <input type="text" class="form__control"
                                       v-model="code2Auth"
                                       name="code2Auth" id="code2Auth">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button v-if="status2fa == 0" :disabled="code2Auth === ''"
                              type="button"
                              @click="toggle2fa" class="btn btn-info satefy__btn">
                        {{$t('activate')}}
                      </button>
                      <button v-if="status2fa == 1" :disabled="code2Auth === ''"
                              type="button"
                              @click="toggle2fa" class="btn btn-info satefy__btn satefy__btn--deact">
                        {{$t('deactivate')}}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="account-verification__item">
                <h2 class="account-verification__subtitle">{{$t('secret_phrase')}}</h2>
                <div class="container-fluid pe-0 ps-0">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="alert text-grey40">
                        <svg class="alert__icon">
                          <use xlink:href="#i-warning"></use>
                        </svg>
                        <div class="alert__text">
                          <p class="mt-0 mb-0">{{$t('secret_phrase_text')}}</p>
                        </div>
                      </div>
                      <div class="secret-phrase" v-if="seedGenerated == 0" @click="genSeed">
                        <svg class="secret-phrase__icon">
                          <use xlink:href="#i-lock"></use>
                        </svg>
                        <div class="secret-phrase__text">{{$t('tap_too')}}</div>
                      </div>
                      <div class="secret-phrase" v-if="seedGenerated == 1">
                        <div class="secret-phrase__text" style="color: #fff">{{ userSeed }}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="satefy__note" v-html="this.$t('save_secret_phrase_text')">
                      </div>
                    </div>
                  </div>

                  <div v-if="seedGenerated == 1 && hideSeedBtn == 0" style="max-width:478px; margin-top:20px">
                    <label for="pin2email" class="form__label d-block">
                      <i class="fas fa-info-circle"></i> {{$t('pin2email')}}
                    </label>
                    <input type="text" class="form__control" id="pin2email"
                           style="width: 60%; margin-right: 10px;"
                           v-model="pin2email" :placeholder="$t('pin2emailph')"/>
                    <button type="button" class="btn btn-gray-outline wallet__btn"
                              :disabled="sendCodeTimer > 0"
                            style="display: inline-flex; width: 36%; text-align: center; justify-content: center;"
                            @click="pin2faMail">
                      <span v-if="sendCodeTimer > 0">
                        {{ sendCodeTimer }} сек.<br>
                        <span style="font-size: 7px;">{{$t('send_repeat')}}</span>
                      </span>
                      <span v-else>{{$t('send')}}</span>
                    </button>

                  <div class="form-item mt-3" v-if="status2fa == 1">
                    <label for="login2FA" class="form__label d-block">{{$t('google_code')}}</label>
                    <input type="text" id="login2FA" class="form__control pwd" v-model="facode" autocomplete="password" required>
                    </div>
                  </div>

                  <button class="btn btn-info satefy__btn satefy__btn--act-secret-phrase" v-if="seedGenerated == 0 && hideSeedBtn == 0" disabed>{{$t('activate')}}</button>
                  <button type="button" class="btn btn-info satefy__btn" v-if="seedGenerated == 1 && hideSeedBtn == 0" @click="setSeed">{{$t('activate')}}</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { api } from "@/api.ts";
import TopNav from '@/components/TopNav/TopNav';
import ProfileMenu from '@/components/ProfileMenu/ProfileMenu';
import '@/assets/css/style_main.min.css';
import {commitToggleModal} from '@/store/main/mutations';
import {readUser} from '@/store/main/getters';
import axios from 'axios';
import {readUserFp} from '@/store/main/getters';
import {commitSetUser} from '@/store/main/mutations';
import VueQRCodeComponent from 'vue-qr-generator';

export default {
  components: {
    TopNav,
    ProfileMenu,
    VueQRCodeComponent,
  },
  name: 'Profile',
  data() { return {
    pinCode: '',
    qr: '',
    code2Auth: '',
    auth: false,
    topnav: [
       { name: 'swap', url: 'swap.html' },
       { name: 'wallet', url: '/wallet.html' },
       { name: 'stake',url: '/stake.html' }
    ],
    status2fa: 0,
    userSeed: '',
    seedGenerated: 0,
    hideSeedBtn: 0,
    sendCodeTimer: 0,
    pin2email: '',
    facode: '',
    old_pwd: '',
    new_pwd: '',
    re_new_pwd: ''
 }},
 computed: {
   user() {
     return readUser(this.$store);
   },
 },
 methods: {
    setPassword() {
      if(this.old_pwd == '' || this.new_pwd == '' || this.re_new_pwd == '') {
        this.$noty.error(this.$t('insert_old_and_new_password'), { timeout: 2000, progressBar: true });
        return;
      }
      axios.post(this.$store._modules.root.state.main.API_URL+'/user',
        {method: 'setPassword',
          data: { fp: readUserFp(this.$store), uid: this.user.uid, old_pwd: this.old_pwd, new_pwd: this.new_pwd, re_new_pwd: this.re_new_pwd}},{headers: {'Authorization':'Bearer ' + this.user.hash}}).then(r => {
        console.log(r.data);
        if(r.data.data.ok == true) {
          this.old_pwd = '';
          this.new_pwd = '';
          this.re_new_pwd = '';
          this.$noty.success(r.data.data.msg, { timeout: 2000, progressBar: true });
        } else {
          this.$noty.error(r.data.data.msg, { timeout: 2000, progressBar: true });
        }
      });
    },
    genSeed() {
      axios.post(this.$store._modules.root.state.main.API_URL+'/user',
        {method: 'genSeed',
          data: { fp: readUserFp(this.$store), uid: this.user.uid}},{headers: {'Authorization':'Bearer ' + this.user.hash}}).then(r => {
        console.log(r.data);
        if(r.data.data.ok == true && r.data.data.seed) {
          this.userSeed = r.data.data.seed;
          this.seedGenerated = 1;
          this.$noty.success(this.$t('secret_phrase_generated'), { timeout: 2000, progressBar: true });
        } else {
          this.$noty.error(this.$t('secret_phrase_error'), { timeout: 2000, progressBar: true });
        }
      });
    },
    setSeed() {
      if(this.seedGenerated == 0) {
        return;
      }
      axios.post(this.$store._modules.root.state.main.API_URL+'/user',
        {method: 'setSeed',
          data: { fp: readUserFp(this.$store), uid: this.user.uid, seed: this.userSeed, facode: this.facode, pin2mail: this.pin2email}},{headers: {'Authorization':'Bearer ' + this.user.hash}}).then(r => {
        console.log(r.data);

        // check email
        if(r.data.error == 'pinRequired') {
          this.$noty.error(this.$t('insert_email'), { timeout: 2000, progressBar: true });
          return;
        }
        if(r.data.error == 'pinWrong') {
          this.$noty.error(this.$t('invalid_email'), { timeout: 2000, progressBar: true });
          return;
        }

        // check 2fa
        if(r.data.error == '2faRequired') {
          this.$noty.error(this.$t('confirm_auth_with_google'), { timeout: 2000, progressBar: true });
          this.need2fa = 1;
          return;
        }
        if(r.data.error == '2faWrong') {
          this.$noty.error(this.$t('invalid_auth_with_google'), { timeout: 2000, progressBar: true });
          return;
        }

        if(r.data.data.ok == true) {
          this.hideSeedBtn = 1;
          this.$noty.success(this.$t('secret_phrase_activate_ok'), { timeout: 2000, progressBar: true });
        } else {
          this.$noty.error(this.$t('secret_phrase_activate_error'), { timeout: 2000, progressBar: true });
        }
      });
    },

    checkAuth() {
      if (this.user.hash) return this.auth = true;
      commitToggleModal(this.$store, 'login');
      return false;
    },
    generatePinCode() {
      axios.post(this.$store._modules.root.state.main.API_URL+'/2fa',
          {method: 'getKey',
            data: {uid: this.user.uid}},{headers: {'Authorization':'Bearer ' + this.user.hash}}).then(r => {
        if (r.data.code === 2) {
          this.pinCode = r.data.data.key2fa;
          this.qr = r.data.data.qr;
          this.$noty.success(this.$t('Key generated'), { timeout: 2000, progressBar: true });
        }
      });
    },
    toggle2fa() {
      axios.post(this.$store._modules.root.state.main.API_URL+'/2fa',
          {
            method: 'togglePin',
            data: {uid: this.user.uid, pin2fa: this.code2Auth}},
            {headers: {'Authorization':'Bearer ' + this.user.hash}})
        .then(r => {
          console.log('ppp');
          console.log(r);
        if (r.data.code === 2) {
          localStorage.isHasPin = r.data.data.pin;
          commitSetUser(this.$store, {
            ref: '',
            un: this.user.username,
            hash: this.user.hash,
            uid: this.user.uid,
            isHasPin: r.data.data.pin,
          });
          if (r.data.data.pin === 1) {
            this.$noty.success(this.$t('2FA successfully activated'), { timeout: 4000, progressBar: true });
            this.status2fa = 1;
            this.code2Auth = '';
          } else {
            this.$noty.success(this.$t('2FA successfully deactivated'), { timeout: 4000, progressBar: true });
            this.status2fa = 0;
            this.code2Auth = '';
          }
        } else {
          this.$noty.error(this.$t('Error'), { timeout: 3000, progressBar: true });
        }
      });
    },
    copyKey() {
      if(this.pinCode == '') return;
      const testingCodeToCopy = document.querySelector('#pinCode');
      // @ts-ignore
      testingCodeToCopy.setAttribute('type', 'text');
      if (navigator.userAgent.match(/ipad|iphone/i)) {
        const range = document.createRange();
        // @ts-ignore
        range.selectNode(testingCodeToCopy);
        const selection = window.getSelection();
        if (selection) {
          selection.removeAllRanges();
          selection.addRange(range);
        }
      } else {
        // @ts-ignore
        testingCodeToCopy.select();
      }

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        this.$noty.success(this.$t('Copied successfully'), { timeout: 2000, progressBar: true });
      } catch (err) {
        this.$noty.error(this.$t('Error, unable to copy'), { timeout: 2000, progressBar: true });
      }
      // @ts-ignore
      //testingCodeToCopy.setAttribute('type', 'hidden');
      // @ts-ignore
      window.getSelection().removeAllRanges();
    },
    loadProfile(){
      axios.post(this.$store._modules.root.state.main.API_URL+'/user',
          {method: 'loadProfile',
            data: { fp: readUserFp(this.$store), uid: this.user.uid}},{headers: {'Authorization':'Bearer ' + this.user.hash}}).then(r => {
        console.log('loadProfile', r.data);
        if (typeof(r.data.data.profile)=='object') this.status2fa = r.data.data.profile.pin;
      });
    },
    resendCodeTimer() {
      if (this.sendCodeTimer > 0) {
        this.sendCodeTimer -= 1
        setTimeout(() => { this.resendCodeTimer() }, 1000);
      }
    },
    pin2faMail() {
      if (!this.checkAuth()) return false;

      api('wallet', 'sendEmailPin', {type: 'pin2fa', symbol: this.symbol})
      .then( r => {
        console.log('transferCoin pin2fa', r);
        
        if(r.data.ok) {
          this.$noty.info(this.$t('email_pin_sent'), { timeout: 2000, progressBar: true });

          this.sendCodeTimer = 60;
          this.resendCodeTimer();
        } else {
          this.$noty.error(this.$t('alert_error'), { timeout: 2000, progressBar: true });
        }

        
      });
    },
 },
 mounted() {
    this.loadProfile()
 }
}
</script>

<style scoped>

</style>
